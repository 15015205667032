import React, { useState, useEffect } from 'react';
import { LogIn } from 'lucide-react';  // Importa el icono de lucide-react
import { useNavigate } from 'react-router-dom';
import Logo from '../img/logo.png';
import './navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const navMenu = document.querySelector('.nav-menu');

    if (menuOpen) {
      navMenu.classList.add('show');
    } else {
      navMenu.classList.remove('show');
    }
  }, [menuOpen]);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const goToPoliticas = () => {
    navigate('/politicas');
  };

  return (
    <nav className={scrolled ? 'scrolled' : ''}>
      <button className="hamburger-menu" onClick={handleMenuClick}>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
      <ul className="nav-menu">
        <li>
          <img className="nav_logo" src={Logo} alt="Planius logo" />
        </li>
        <li>
          <a href="#home">Inicio</a>
        </li>
        <li>
          <a href="#howWorks">¿Cómo funciona?</a>
        </li>
        <li>
          <a href="#diferenciacion">Diferenciación</a>
        </li>
        <li>
          <a href="#contact">Características</a>
        </li>
        <li className="nav-right">
          <a className="initButton" href="https://app.planius.ai">
            <LogIn /> Inicia Sesión
          </a>
          <button className="initButton" onClick={goToPoliticas}>Políticas</button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
