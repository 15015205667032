import React from 'react';
import computerFrameImage from '../img/compu.png'; // Asegúrate de que la ruta sea correcta
import './videoporque.css';

function VideoPorQue() {
    return (
        <div className="videoporque-container">
            <div className="videoporque-contentWrapper">
                <div className="videoporque-videoFrameWrapper">
                    <img src={computerFrameImage} alt="computer frame" className="videoporque-computerFrame" />
                    <div className="videoporque-videoWrapper">
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/3nyVet3Q4Vw" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className="videoporque-textWrapper">
                    <span className="videoporque-whySubTitle">¿POR QUÉ PLANIUS?</span>
                    <h1 className="videoporque-whyTitle">Porque te guiamos con una metodología efectiva y la IA de Plany Bot para planificar y alcanzar tus objetivos de manera eficiente.</h1>
                    <button className="videoporque-contactButton">Habla con nosotros</button>
                </div>
            </div>
        </div>
    );
}

export default VideoPorQue;
