import React, { useEffect, useState } from 'react';
import './beneficios.css';

function Beneficios() {
    const [countX10, setCountX10] = useState(0);
    const [count98, setCount98] = useState(0);
    const [count80, setCount80] = useState(0);

    // Función para el conteo progresivo
    useEffect(() => {
        const incrementNumber = (setCount, target, increment) => {
            let count = 0;
            const interval = setInterval(() => {
                count += increment;
                if (count >= target) {
                    clearInterval(interval);
                    setCount(target);
                } else {
                    setCount(count);
                }
            }, 50); // Velocidad de la animación
        };

        incrementNumber(setCountX10, 10, 1);
        incrementNumber(setCount98, 84, 2);
        incrementNumber(setCount80, 80, 2);
    }, []);

    return (
        <div id="beneficios" className="beneficios-section">
          <h1 className="beneficios-title">Quienes usan Planius experimentan</h1>
          <div className="beneficios-facts-container">
            <div className="beneficios-fact beneficios-reveal">
              <p className="beneficios-big-number">x{countX10}</p>
              <p>Mejoran su productividad hasta 10 veces</p>
            </div>
            <div className="beneficios-fact beneficios-reveal">
              <p className="beneficios-big-number">{count98}%</p>
              <p>Menos ansiedad</p>
            </div>
            <div className="beneficios-fact beneficios-reveal">
              <p className="beneficios-big-number">{count80}%</p>
              <p>Más control del tiempo</p>
            </div>
          </div>
        </div>
    );
}

export default Beneficios;
