import React from 'react';
import { Instagram, Youtube, MessageCircle } from 'lucide-react';
import startupChileLogo from '../img/startupchile.png'; // Asegúrate de tener el logo en la carpeta correcta
import './footer.css';

function Footer() {
    const currentYear = new Date().getFullYear(); // Obtenemos el año actual

    return (
        <footer className="footer-container">
            <div className="footer-wave"></div>
            <div className="footer-content">
                <div className="footer-column">
                    <h3>Acelerados por</h3>
                    <img src={startupChileLogo} alt="StartupChile logo" className="footer-logo" />
                </div>
                <div className="footer-column">
                    <h3>Contáctanos</h3>
                    <div className="footer-icons">
                        <Instagram className="footer-icon" />
                        <Youtube className="footer-icon" />
                        <MessageCircle className="footer-icon" />
                    </div>
                </div>
                <div className="footer-column">
                    <h3>Sobre nosotros</h3>
                    <ul>
                        <li><a href="#workwithus">Trabaja con nosotros</a></li>
                        <li><a href="#policies">Políticas</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© {currentYear} Todos los derechos reservados. Planius.</p>
            </div>
        </footer>
    );
}

export default Footer;
