import React from "react";
import './comoFunciona.css';

function ComoFunciona() {
  return (
    <div>
      <h1 className="comoFunciona-title">Convierte el caos en claridad y productividad con Planius</h1>
      <p className="comoFunciona-description">
        Sabemos lo que es sentir que el día se te va de las manos, que las tareas se acumulan y que, por más que lo intentes, no logras avanzar. La procrastinación, la falta de claridad y la alta carga mental son obstáculos constantes que generan ansiedad y te alejan de tus metas. En Planius, entendemos que planificar puede ser abrumador y que mantener la motivación es un desafío. Por eso, creamos una herramienta que pone a tu disposición una metodología y la potencia de la inteligencia artificial trabajando como tu asistente personal. Siguiendo la metodología Planius podrás recuperar una mente tranquila para retomar el control de tu vida, enfocarte en lo que realmente importa y alcanzar tus objetivos con eficiencia y tranquilidad."
        
      </p>
    </div>
  );
}

export default ComoFunciona;
