import React from "react";
import './diferenciacion.css';

function Diferenciacion() {
  return (
    <div >
      <h1>¿En qué nos diferenciamos?</h1>
      <p>
        A diferencia de la competencia, Planius utiliza inteligencia artificial para llevar tu planificación al siguiente nivel. Captura tareas de forma masiva a través de tu voz y deja que nuestro asistente personal Plany Bot las organice por ti, ayudándote a planificar basado en las teorías de productividad elevando tu nivel. Con Planius, no solo gestionas tu tiempo de manera eficiente, sino que también cuentas con una metodología clara que simplifica la planificación y te guía en cada paso. Es más que una herramienta, es el aliado que te acompaña para transformar tu vida y alcanzar tus metas con mayor facilidad.
      </p>
    </div>
  );
}

export default Diferenciacion;
