import React from "react";
import { Bot, BarChart2, List, UserCheck, Settings, PieChart, Sliders } from "lucide-react";
import './caracteristicas.css';

function Caracteristicas() {
  return (
    <div className="caracteristicas-container">
      <h1 className="caracteristicas-title">¡Haz más en menos tiempo!</h1>
      <div className="caracteristicas-tarjetas">
        <div className="caracteristicas-card">
          <List className="caracteristicas-icon" />
          <h4 className="caracteristicas-titleCard">Metodología Efectiva</h4>
          <p className="caracteristicas-text">
            Un sistema que te guía para organizar y alcanzar tus objetivos de forma clara,
            asegurando que puedas avanzar en tu planificación de manera efectiva.
          </p>
        </div>

        <div className="caracteristicas-card">
          <Bot className="caracteristicas-icon" />
          <h4 className="caracteristicas-titleCard">Optimización de tareas con IA</h4>
          <p className="caracteristicas-text">
            Un asistente de inteligencia artificial que te ayuda a planificar y mantenerte
            enfocado en lo importante, agilizando la gestión de tus tareas.
          </p>
        </div>

        <div className="caracteristicas-card">
          <UserCheck className="caracteristicas-icon" />
          <h4 className="caracteristicas-titleCard">Priorización Inteligente</h4>
          <p className="caracteristicas-text">
            Con Plany bot, prioriza las tareas más importantes en función de las teorías de productividad,
            asegurando que siempre estés enfocado en lo que realmente importa.
          </p>
        </div>

        <div className="caracteristicas-card">
          <Sliders className="caracteristicas-icon" />
          <h4 className="caracteristicas-titleCard">Interfaz Intuitiva</h4>
          <p className="caracteristicas-text">
            Fácil de usar, con una interfaz que facilita tu planificación diaria y que sigue un
            enfoque minimalista para que no pierdas tiempo en lo que no importa.
          </p>
        </div>

        <div className="caracteristicas-card">
          <Settings className="caracteristicas-icon" />
          <h4 className="caracteristicas-titleCard">Personalización</h4>
          <p className="caracteristicas-text">
            Adapta Planius a tu estilo de vida y necesidades, configurando las opciones que mejor
            se ajusten a tu forma de trabajar y tus objetivos.
          </p>
        </div>

        <div className="caracteristicas-card">
          <PieChart className="caracteristicas-icon" />
          <h4 className="caracteristicas-titleCard">Análisis de Progreso</h4>
          <p className="caracteristicas-text">
            Recibe reportes que muestran tu avance y te ayudan a mejorar, permitiéndote
            visualizar tu progreso y optimizar tu rendimiento.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Caracteristicas;
