import React, { useState, useEffect } from "react";

function TitleText() {
  const [text, setText] = useState("");
  const [textIa, setTextIa] = useState("En línea");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(50);
  const [escribiendoClass, setEscribiendocClass] = useState("des");

  const toRotate = ["Reduce la ansiedad", "Optimiza tu calendario con IA", "Potencia tu vida", "Registra todas tus tareas y eventos en un solo lugar", "Mide tu rendimiento"];
  const period = 3000; // Tiempo que espera antes de empezar a borrar.

  useEffect(() => {
    let ticker;

    if (isDeleting) {
      setTextIa("En línea");
      setEscribiendocClass("des");
      ticker = setTimeout(() => {
        setText((currentText) =>
          currentText.substring(0, currentText.length - 40)
        );
        setTypingSpeed(100); // Velocidad de borrado más rápida
      }, typingSpeed);
    } else {
      setTextIa("Planibot está escribiendo...");
      setEscribiendocClass("act");
      ticker = setTimeout(() => {
        setText((currentText) =>
          toRotate[loopNum].substring(0, currentText.length + 1)
        );
        setTypingSpeed(100); // Restablece la velocidad normal cuando está escribiendo
      }, typingSpeed);
    }

    return () => {
      clearTimeout(ticker);
    };
  }, [text, isDeleting]);

  useEffect(() => {
    let timeout;
    if (!isDeleting && text === toRotate[loopNum]) {
      setTextIa("En línea");
      setEscribiendocClass("des");
      timeout = setTimeout(() => {
        setIsDeleting(true);
        setTypingSpeed(100); // Restablece velocidad de borrado
      }, period);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setLoopNum((loopNum) => (loopNum + 1) % toRotate.length);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [text, isDeleting, loopNum]);

  // Función para renderizar el texto con el cursor
  const renderTextWithCursor = () => {
    if (textIa === "Planibot está escribiendo...") {
      return (
        <span>
          {text}<span className="cursor"></span>
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  return (
    <div className="marginAuto">
      <h1> {renderTextWithCursor()}</h1>
      <p className="centerDiv">
        <div className={`iaEscribiendo ${escribiendoClass}`}>🤖 {textIa}</div>
        Planius es la herramienta que te permite organizar todos los aspectos de tu vida en un solo lugar, combinando una metodología efectiva y la inteligencia artificial de Plany Bot para ayudarte a planificar, priorizar y alcanzar tus objetivos con facilidad. Ya sea para tus metas personales o profesionales, Planius te guía de forma clara y sencilla, brindándote la estructura y el enfoque que necesitas para ser más eficiente y reducir el estrés de la planificación diaria."
      </p>
    </div>
  );
}

export default TitleText;
